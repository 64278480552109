/* eslint-disable no-console */
import cuid from "cuid";
import firebase from "firebase/compat/app";
import { get } from "lodash";
import moment from "moment";
import "moment/locale/el";
import { countries } from "../../enums";
import {
  IAddress,
  ICity,
  IPackageHotelPublic,
  IPassenger,
  IRoom,
  IRoomInput,
} from "../typings";
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getFileExtension = (filename: string) =>
  // eslint-disable-next-line no-bitwise
  filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);

export const getFlightDuration = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const duration = moment.utc(endDate.diff(startDate)).format("HH:mm");
  return duration;
};

export const getPackageDuration = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const duration1 = moment.duration(endDate.diff(startDate)).asDays();
  const duration = Math.round(duration1);
  return {
    nights: duration,
    days: duration + 1,
    daysNights: `${duration + 1} Ημέρες / ${duration} Νύκτες `,
  };
};

export const formatReadableAddress = (
  address: IAddress | undefined
): string => {
  if (!address) return "";
  const streetLine1 = get(address, "streetLine1") || "";
  const streetLine2 = get(address, "streetLine2") || "";
  const city = get(address, "city.name") || "";
  const countryName = get(address, "country.name") || "";
  return [streetLine1, streetLine2, city, countryName]
    .filter((f) => !!f)
    .join(", ");
};

export const formatReadableCity = (city: ICity | undefined): string => {
  if (!city) return "";
  const name = get(city, "name") || "";
  const countryName = countries[city.countryCode]?.label || "";
  return [name, countryName].filter((f) => !!f).join(", ");
};

export const convertRoomsToPassenger = (rooms: IRoom[]): IPassenger[] => {
  const roomTypes: { [key: string]: number } = {
    single: 1,
    double: 2,
    triple: 3,
    quadruple: 4,
  };
  const passengers: IPassenger[] = [];

  rooms.forEach((room, index) => {
    const adultNumbers = roomTypes[room.roomType];
    passengers.push(
      ...Array<IPassenger>(adultNumbers).fill({
        id: cuid(),
        type: "ADULT",
        title: "",
        firstName: "",
        lastName: "",
        roomId: room.id,
        roomName: `Δωμάτιο ${index + 1}`,
      })
    );
    passengers.push(
      ...Array<IPassenger>(room.numberChilds).fill({
        id: cuid(),
        type: "CHILD",
        title: "",
        firstName: "",
        lastName: "",
        roomId: room.id,
      })
    );
  });
  return passengers;
};

export const getYearMonthsOptions = () =>
  Array.apply(0, Array(12)).map((_, i) => ({
    label: moment().locale("el").add(i, "month").format("MMMM - YYYY"),
    value: moment().add(i, "month").format("MM-YYYY"),
  }));

export const urlStringToOBject = (search: string) =>
  JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`
  );

export const dateFormatFirestore = (data: any) => {
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }
  return data;
};

const roomTypes: { [key: string]: number } = {
  single: 1,
  double: 2,
  triple: 3,
  quadruple: 4,
};
export const getRoomCost = (
  room: IRoomInput,
  hotel: IPackageHotelPublic
): number => {
  const adultPrice = get(hotel, `${room.roomType}RoomPricePublic`, 0);
  const numberOfAdults = get(roomTypes, room.roomType, 0);

  const firstChildPrice =
    room.numberChilds > 0 ? get(hotel, "firstChildPricePublic", 0) : 0;
  const secondChildPrice =
    room.numberChilds > 1 ? get(hotel, "secondChildPricePublic", 0) : 0;
  return adultPrice * numberOfAdults + firstChildPrice + secondChildPrice;
};

export const jccConvertAmount = (amount: number): string => {
  const purchaseAmt = `${amount}.00`;
  const purchaseAmtPadStart = purchaseAmt.padStart(13, "0");
  return purchaseAmtPadStart.replace(/[.]/g, "");
};
