import React from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { notification, Popconfirm, Switch } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { Input, Popover, Select, Space, Table } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { ICity, IPackage } from "../../../App/common/typings";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import PackageFlights from "../PackageViewFrontEnd/PackageFlights";
import { dateFormatFirestore } from "../../../App/common/util/util";
import { activeTogglePackageInFirestore } from "../../../App/firestore/firestoreService";

export interface IPackageListProps {
  packages: IPackage[];
  loading: boolean;
  searchPackage: (search: string) => void;
  filterPackage: (cityId: string) => void;
  addPackage: () => void;
  deletePackage: (id: string) => void;
  duplicate: (id: string) => void;
  sorting: (name: string, order: any) => void;
}

export const PackageList: React.FC<IPackageListProps> = ({
  packages,
  addPackage,
  deletePackage,
  loading,
  searchPackage,
  filterPackage,
  duplicate,
  sorting,
}) => {
  const { cities } = useSelector((state: any) => state.cities);
  // const [orderPackages, setOrderPackages] = React.useState<{
  //   name: string;
  //   order: "asc" | "desc" | boolean;
  // }>({
  //   name: "name",
  //   order: "asc",
  // });
  const handelOnSearch = (value: string) => {
    searchPackage(value);
  };
  const handelPublishChange = (id: string) => async (status: boolean) => {
    try {
      await activeTogglePackageInFirestore(id, status);
      notification.success({
        message: "Update Package Status",
        description: "Package status update successfully",
      });
    } catch (error: any) {
      notification.error({
        message: "Update Package Status",
        description: error.message,
      });
    }
  };

  const columnsPackageList: ColumnsType<IPackage> = [
    {
      title: "Package Title",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: any) => (
        <Link to={`/admin/package/${record.id}`} target="_blank">
          {v}
        </Link>
      ),
      sorter: (a: IPackage, b: IPackage) => a.name.localeCompare(b.name),
    },

    // {
    //   title: "Provider",
    //   dataIndex: "provider",
    //   key: "provider",
    //   render: (v: IProvider) => v.companyName,
    // },

    {
      title: "Departure City",
      dataIndex: "departureCity",
      key: "departureCity",
      render: (v: ICity) => v.name,
      sorter: (a: IPackage, b: IPackage) =>
        a.departureCity.name.localeCompare(b.departureCity.name),
    },
    {
      title: "From Date",
      dataIndex: "departureDate",
      key: "departureDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
      sorter: (a: IPackage, b: IPackage) =>
        +moment(a.departureDate).format("YYYYMMDD") -
        +moment(b.departureDate).format("YYYYMMDD"),
    },
    {
      title: "To Date",
      dataIndex: "returnDate",
      key: "returnDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
      sorter: (a: IPackage, b: IPackage) =>
        +moment(a.returnDate).format("YYYYMMDD") -
        +moment(b.returnDate).format("YYYYMMDD"),
    },
    {
      title: "Flight price",
      dataIndex: "totalFlightPricePerAdult",
      key: "totalFlightPricePerAdult",
      sorter: (a: IPackage, b: IPackage) =>
        +(a?.totalFlightPricePerAdult || 0) -
        (b?.totalFlightPricePerAdult || 0),
      render: (v: number, record: IPackage) => (
        <Popover
          content={
            <PackageFlights
              flights={
                record.flights?.map((flight) => dateFormatFirestore(flight)) ||
                []
              }
            />
          }
        >
          <Button type="primary">{`€ ${v}`}</Button>
        </Popover>
      ),
    },
    {
      title: "Starting price",
      dataIndex: "minPricePerAdult",
      key: "minPricePerAdult",
      sorter: (a: IPackage, b: IPackage) =>
        +(a?.minPricePerAdult || 0) - (b?.minPricePerAdult || 0),
      render: (v: number) => `€ ${v}`,
    },
    {
      title: "Publish",
      dataIndex: "status",
      key: "status",
      sorter: (a: IPackage, b: IPackage) =>
        a.status === b.status ? 0 : a.status ? -1 : 1,
      render: (v: boolean, record: IPackage) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={v}
          onChange={handelPublishChange(record.id)}
        />
      ),
      filters: [
        {
          text: "Publish",
          value: true,
        },
        {
          text: "UnPublish",
          value: false,
        },
      ],
      onFilter: (value: any, record: IPackage) => record.status === !!value,
    },
    {
      title: "Created Date",
      dataIndex: "createDate",
      key: "createDate",
      sorter: (a: IPackage, b: IPackage) =>
        +moment(a.createDate).format("YYYYMMDD") -
        +moment(b.createDate).format("YYYYMMDD"),
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (v: any, record: IPackage) => (
        <Space size="middle">
          <Link to={`/admin/package/${record.id}`} target="_blank">
            <EditOutlined />
          </Link>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => deletePackage(record?.id || "0")}
          >
            <Button type="link">
              {" "}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => duplicate(record?.id || "0")}
          >
            <Button type="link">
              {" "}
              <CopyOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    // sorting(sort.columnKey, sort.order === "descend" ? "desc" : "asc");
  };

  return (
    <div>
      <TableHeader>
        <Button
          type="primary"
          onClick={addPackage}
          block
          icon={<PlusOutlined />}
        >
          Add Package
        </Button>
        <Input.Search
          placeholder="Search Package"
          onSearch={handelOnSearch}
          style={{ flex: 1 }}
          allowClear
        />

        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder="Select City"
          optionFilterProp="children"
          onChange={filterPackage}
          filterOption={(input, option: any) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={cities.map(({ name, id }: any) => ({
            value: id,
            label: name,
          }))}
        />
      </TableHeader>
      <Table
        columns={columnsPackageList}
        dataSource={packages}
        loading={loading}
        rowKey="id"
        scroll={{ x: 1000, y: 600 }}
        onChange={handleTableChange}
      />
    </div>
  );
};
