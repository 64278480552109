/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { openModal } from "../../../App/redux/data/modals/modalReducer";
import { Divider, Logo, Space } from "../../atoms";
import { BurgerMenuBtn, LeftMenu } from "../../molecules";
import { signOutFirebase } from "../../../App/firestore/firebaseService";
import { HeaderWrapper } from "./Header.style";

interface IHeaderProps {
  isMobile: boolean;
  setShowMenu: (show: boolean) => void;
  showMenu: boolean;
}
export const Header: React.FC<IHeaderProps> = ({
  isMobile,
  setShowMenu,
  showMenu,
}) => {
  const location = useLocation();
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const [, setIsPasydy] = React.useState(false);
  const { authenticated, currentUser } = useSelector(
    (state: any) => state.auth
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogin = () => {
    dispatch(
      openModal({ modalType: "LoginForm", modalProps: { title: "Login" } })
    );
    // setAuthenticated(true);
    // history.push("/admin/");
  };
  const handleLogout = async () => {
    try {
      await signOutFirebase();
      history.push("/");
    } catch (e) {
      notification.error({
        message: "Log out failed",
        description: "Log out failed",
      });
    }
  };
  const handleShowMobileMenu = (e: any) => {
    setShowMenu(e.target.checked);
  };
  React.useEffect(() => {
    console.log(location);
    if (
      selectedPackage?.partnerId === "PASYDY" ||
      location.pathname.includes("pasydy")
    ) {
      setIsPasydy(true);
    } else {
      setIsPasydy(false);
    }
  }, [location, selectedPackage]);
  return (
    <HeaderWrapper>
      <Logo />
      {/* {isPasydy && (
        <div className="header-pasydy-logo-name">
          για
          <img className="header-pasydy-logo" src="/assets/PASYDY.png" alt="" />
        </div>
      )} */}

      {!isMobile && (
        <Space style={{ display: "flex" }} className="header-menu">
          <Link to={"/packages"}>
            <FormattedMessage
              description="Packages"
              defaultMessage="Packages"
              id="46PJ2T"
            />
          </Link>
          <Divider type="vertical" />
          <Link to="/hotels">
            <FormattedMessage
              description="Hotels"
              defaultMessage="Hotels"
              id="qrGoSh"
            />
          </Link>
          <Divider type="vertical" />
          <Link to="/things-to-do">
            <FormattedMessage
              description="Things to do"
              defaultMessage="Things to do"
              id="cb2l99"
            />
          </Link>
        </Space>
      )}
      {!isMobile && (
        <div className="header-phone-number">
          <a href="mailto:reservations@poupas.com.cy">
            <MailOutlined className="header-phone-number-icon" />
          </a>

          <PhoneOutlined className="header-phone-number-icon" />
          <div className="header-phone-number-text">
            <div className="header-phone-number-first">7008 7003</div>
            <div>+35722252508</div>
          </div>
        </div>
      )}
      {!isMobile && (
        <LeftMenu
          authenticated={authenticated}
          login={handleLogin}
          logout={handleLogout}
          currentUser={currentUser}
        />
      )}
      {isMobile && (
        <div className="header-mobile-icon">
          <BurgerMenuBtn
            onClick={handleShowMobileMenu}
            checked={showMenu}
          ></BurgerMenuBtn>
        </div>
      )}
    </HeaderWrapper>
  );
};
