/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { PhoneOutlined } from "@ant-design/icons";
import { openModal } from "../../../App/redux/data/modals/modalReducer";
import { Button } from "../../atoms";
import { signOutFirebase } from "../../../App/firestore/firebaseService";
import { MobileMenuWrapper } from "./MobileMenu.style";
interface IMobileMenuProps {
  showMenu: boolean;
  setShowMenu: (e: boolean) => void;
}
export const MobileMenu: React.FC<IMobileMenuProps> = ({
  showMenu,
  setShowMenu,
}) => {
  const { authenticated } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogin = () => {
    dispatch(
      openModal({ modalType: "LoginForm", modalProps: { title: "Login" } })
    );
    // setAuthenticated(true);
    // history.push("/admin/");
  };
  const handleLogout = async () => {
    try {
      await signOutFirebase();
      history.push("/");
    } catch (e) {
      notification.error({
        message: "Log out failed",
        description: "Log out failed",
      });
    }
  };
  const handleShowMobileMenu = () => {
    setShowMenu(false);
  };
  // React.useEffect(() => {
  //   if (
  //     selectedPackage?.partnerId === "PASYDY" ||
  //     location.pathname.includes("pasydy")
  //   ) {
  //     setIsPasydy(true);
  //   } else {
  //     setIsPasydy(false);
  //   }
  // }, [location, selectedPackage]);
  return (
    <MobileMenuWrapper showMenu={showMenu}>
      <div className="mobile-menu-title">
        <FormattedMessage
          description="Plan Your Holidays"
          defaultMessage="Plan Your Holidays"
          id="yCqdwg"
        />
      </div>
      <div className="mobile-menu-sub-title">
        <FormattedMessage
          description="I 'M SEARCHING FOR"
          defaultMessage="I 'M SEARCHING FOR"
          id="RHBL9W"
        />
      </div>
      <div className="mobile-menu-items">
        <Link
          to={"/packages"}
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Packages"
            defaultMessage="Packages"
            id="46PJ2T"
          />
        </Link>

        <Link
          to="/hotels"
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Hotels"
            defaultMessage="Hotels"
            id="qrGoSh"
          />
        </Link>
        <Link
          to="/things-to-do"
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Things to do"
            defaultMessage="Things to do"
            id="cb2l99"
          />
        </Link>
      </div>
      <div className="mobile-menu-footer">
        <div className="mobile-menu-phone-number">
          <PhoneOutlined className="mobile-menu-phone-number-icon" />
          <div className="mobile-menu-phone-number-text">
            <div className="mobile-menu-phone-number-first">
              <a href="tel:70087003">7008 7003</a>
            </div>
            <div>
              <a href="tel:+35722252508">+35722252508</a>
            </div>
          </div>
        </div>
        {!authenticated ? (
          <Button className="mobile-menu-btn" onClick={handleLogin}>
            {" "}
            <FormattedMessage
              description="Login"
              defaultMessage="Login"
              id="gdzUOH"
            />
          </Button>
        ) : (
          <Button className="mobile-menu-btn" onClick={handleLogout}>
            {" "}
            <FormattedMessage
              description="Logout"
              defaultMessage="Logout"
              id="LAaOhU"
            />
          </Button>
        )}
      </div>
    </MobileMenuWrapper>
  );
};
