/* eslint-disable no-console */
import { InputNumber, notification, Switch } from "antd";
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cuid from "cuid";
import { IDestination, IPackage } from "../../../App/common/typings";
import { packageServiceOptions } from "../../../App/enums";
import {
  fetchCategoriesFromFirestore,
  fetchCitiesFromFirestore,
  fetchProvidersFromFirestore,
  listenToPackageFromFirestore,
  updatePackageInFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import { listenToCategories } from "../../../App/redux/data/category/categoryActions";
import { listenToCities } from "../../../App/redux/data/city/cityActions";
import { listenToSelectedPackage } from "../../../App/redux/data/package/packageActions";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Space,
  DatePicker,
  Divider,
} from "../../atoms";
import {
  ItemNotFound,
  Loader,
  DestinationsField,
  SelectField,
  RichTextArea,
} from "../../molecules";
import { listenToProviders } from "../../../App/redux/data/provider/providerActions";
import { PackageDetailsWrapper } from "./Package.style";

// "https://www.mocky.io/v2/5cc8019d300000980a055e76"
export interface IPackageDetailsProps {
  packageId: string;
}

export const PackageDetails: React.FC<IPackageDetailsProps> = ({
  packageId,
}) => {
  const dispatch = useDispatch();
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const { loading, error } = useSelector((state: any) => state.async);
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);
  const description = React.useRef("");
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    console.log(description.current);
    try {
      const values: IPackage = await form.validateFields();
      const city = find(cities, ["id", values.departureCityId]);
      const selectedCategories =
        values.categoryIds?.map((category) =>
          find(categories, ["id", category])
        ) || [];
      const destinations =
        values.destinations?.map(
          (destination: IDestination, index): IDestination => {
            const destinationCity = find(cities, ["id", destination.cityId]);
            const checkInDate =
              index === 0
                ? values.departureDate
                : values?.destinations[index - 1]?.checkOutDate;
            return {
              ...destination,
              city: destinationCity,
              cityId: destination.cityId,
              checkInDate: moment(checkInDate).format("YYYY-MM-DD"),
              checkOutDate: moment(destination.checkOutDate).format(
                "YYYY-MM-DD"
              ),
              hotels: destination.hotels || [],
              id: destination.id || cuid(),
            };
          }
        ) || [];

      await updatePackageInFirestore(packageId, {
        id: packageId,
        name: values.name,
        packageProfit: values.packageProfit,
        serviceInclude: values.serviceInclude,
        departureDate: moment(values.departureDate)?.format("YYYY-MM-DD"),
        departureCity: city,
        departureCityId: values.departureCityId,
        categoryIds: values.categoryIds,
        categoryCodes: selectedCategories.map((category) => category.code),
        destinations,
        categories: selectedCategories,
        description: description.current || selectedPackage?.description || "",
        sellOnline: values.sellOnline,
        status: values.status,
        partnerId: values.partnerId ? "PASYDY" : "",
        pasydyDiscount: values.pasydyDiscount || "",
      });
      notification.success({
        message: "Update",
        description: "Package Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };

  userFirestoreDoc({
    query: () => listenToPackageFromFirestore(packageId),
    data: listenToSelectedPackage,
    deps: [dispatch, packageId],
    shouldExecute: packageId !== "new",
  });
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          order: "asc",
          opr: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  userFirestoreCollection({
    query: () =>
      fetchCategoriesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCategories,
    deps: [dispatch],
  });
  userFirestoreCollection({
    query: () => fetchProvidersFromFirestore(),
    data: listenToProviders,
    deps: [dispatch],
  });
  React.useEffect(() => form.resetFields);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/packages"
        redirectBtnText="Back to Packages"
        itemType="Package"
        itemId={packageId}
      />
    );
  }

  return (
    <PackageDetailsWrapper>
      <Form
        form={form}
        layout="vertical"
        initialValues={selectedPackage}
        onFinish={handleOnFinish}
        autoComplete="off"
        name={`PackageName${selectedPackage?.id}`}
        key={`PackageName${selectedPackage?.id}`}
      >
        <Divider>Details</Divider>
        <Space className="package-details-space">
          <Form.Item
            name="sellOnline"
            label="Sell Online"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item name="status" label="Publish" valuePropName="checked">
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item
            label="Package profit %"
            name="packageProfit"
            rules={[
              {
                required: true,
                message: "Please enter Package profit",
              },
            ]}
          >
            <InputNumber min={1} max={100} placeholder="Package profit %" />
          </Form.Item>
          <Form.Item
            name="partnerId"
            label="View on Pasydy"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          {selectedPackage?.partnerId && (
            <div style={{ backgroundColor: "#eee", padding: 10 }}>
              <h3 style={{ color: "#E91E63" }}>
                {" "}
                Partner: {selectedPackage?.partnerId}
              </h3>

              <Form.Item
                name="onlyPartner"
                label="Only Partner"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>

              <Form.Item
                label="Pasydy Discount %"
                name="pasydyDiscount"
                rules={[
                  {
                    required: true,
                    message: "Please enter Pasydy Discount",
                  },
                ]}
              >
                <InputNumber min={1} max={100} placeholder="Package profit %" />
              </Form.Item>
            </div>
          )}
        </Space>
        <Divider></Divider>
        <Form.Item
          name="name"
          label="Package Name"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <RichTextArea
            description={description}
            html={selectedPackage?.description || ""}
          />
        </Form.Item>
        <Divider></Divider>
        <Form.Item
          name="serviceInclude"
          label="Package Includes"
          rules={[{ required: true, message: "Missing Package Includes" }]}
        >
          <Checkbox.Group options={packageServiceOptions} />
        </Form.Item>
        <div className="package-details-destination">
          <Space className="package-details-space">
            <Form.Item
              label="Departure"
              name="departureDate"
              rules={[{ required: true, message: "Missing Departure" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                onChange={(current) =>
                  form.setFieldsValue({ currentDepartureDate: current })
                }
                disabledDate={(current) => current <= moment()}
              />
            </Form.Item>
            <SelectField
              label="Departure City"
              name="departureCityId"
              rules={[{ required: true, message: "Missing Departure City" }]}
              options={cities.map(({ name, id }: any) => ({
                value: id,
                label: name,
              }))}
            />
          </Space>
        </div>
        <Divider>Destinations</Divider>
        <DestinationsField cities={cities} form={form} />

        <SelectField
          label="Categories"
          name="categoryIds"
          rules={[{ required: true, message: "Missing Category" }]}
          options={categories.map(({ name, id }: any) => ({
            value: id,
            label: name,
          }))}
          mode="multiple"
        />

        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
          <Button
            type="primary"
            // loading={}
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      </Form>
    </PackageDetailsWrapper>
  );
};
